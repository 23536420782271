import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { Pagination } from "antd";

import { ColorRing } from 'react-loader-spinner';
import { base_url } from '../Config/config';
import _fetch from '../Config/api';
import dateFormat from "dateformat";
import { NavLink } from 'react-router-dom';
const Direct = () => {
  const [data, setData] = useState()
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSer, setpageSer] = useState(0);

  const handledashboard = async (page = 1, pageSize = 10) => {
    try {
      const skip = (page - 1) * pageSize;
      setpageSer(skip);
      const requestData = {
        limit: pageSize,
        skip: skip,



      };

      let response = await _fetch(`${base_url}api/user/sponsers`, 'post', requestData);

      if (response.success === true) {
        setData(response);

      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  }


  useEffect(() => {
    handledashboard(currentPage, 10);
  }, [currentPage]);
  const handlePaginationChange = (pageOne) => {
    setCurrentPage(pageOne);

  };

  console.log('data', data);

  const records = () => {
    if (data && data?.data && data?.data?.length > 0) {
      return data?.data?.map((row, i) => {
        const serialNumber = pageSer + i + 1;
        return (
          <div key={i} className="container mt-1">
            <div className="card border-primary">
              {/* <div className="card-header bg-white d-flex justify-content-between align-items-center">
                <span className="badge bg-success">{serialNumber}</span> {row?.symbol}
              </div> */}
              <div className="card-body p-2">
                <div className="row">
                  <div className="col-md-4">
                    <dl className="row mb-0 positionRow">
                      <dt className="col-6">User Id:</dt>
                      <dd className="col-6 p-0">{row?.userId} ({row.name})</dd>

                      <dt className="col-6">Email:</dt>
                      <dd className="col-6 p-0">{row.email}</dd>


                    </dl>
                  </div>
                  <div className="col-md-4">
                    <dl className="row mb-0 positionRow">
                      <dt className="col-6">SponserId</dt>
                      <dd className="col-6 p-0">{row?.sponserId}</dd>
                      <dt className="col-6">Paid Status</dt>
                      <dd className="col-6 p-0">
                        {row?.paidStatus ? 'true' : 'false'}
                      </dd>

                    </dl>
                  </div>
                  <div className="col-md-4">
                    <dl className="row mb-0 positionRow">

                      <dt className="col-6"> Is Verified</dt>
                      <dd className="col-6 p-0">
                        {row?.isVerified ? 'true' : 'false'}
                      </dd>
                      <dt className="col-6">Register At</dt>
                      <dd className="col-6 p-0">{dateFormat(row.createdAt, "yyyy-mm-dd")}</dd>

                    </dl>
                  </div>

                </div>
              </div>
            </div>
          </div>
        );
      });
    } else {
      return <div>
        <p className='text-center mt-5'>Data Not Found</p>
      </div>;
    }
  };



  return (
    <>



      <div className="section2">

        <div className="table-sec">
          <div className="transactions">
            <div className="container mt-5">
              <div className="card">
                {/* <div className='row'>
                  <div className='main-12'>

                    <div className=''>
                      <input type='date' className='form-control' name="start_date" />
                    </div>
                    <div className=''>
                      <input type='date' className='form-control' name="start_date" />
                    </div>
                    <div className=''>
                      <button type='date' className='btn'><i className='fa fa-search'></i></button>
                    </div>
                  </div>



                  <div className="main-table-2 mt-3">
                    <select name="kycStatus" className="input01" id="status">
                      <option value="">Select Currency</option>
                      <option value="1">PENDING</option>
                      <option value="2">APPLIED</option>
                      <option value="3">APPROVED</option>
                      <option value="4">REJECTED</option>
                    </select>
                    <div className="input-group">
                      <input
                        type="text"
                        placeholder="User Id Search"
                      />
                      <button>
                        <i className="fa fa-search"></i> 
                      </button>
                    </div>






                    {/* Filter Button 
                    <div className="filter-btn">
                      <button>
                        <i className="fa fa-filter"></i> {/* Filter Icon 
                        Filters
                      </button>
                    </div>
                  </div>

                </div> */}
              </div>
            </div>
            <ul class="nav nav-pills nav-fill mt-3">
              <li class="nav-item team-current-2">
                <NavLink class="nav-link secend-page " aria-current="page" to="/team">Team</NavLink>
              </li>
              {/* <li class="nav-item"> */}
              <li class="nav-item team-current">
                <NavLink class="nav-link" to="/direct">Direct Team</NavLink>
              </li>

            </ul>
            {records()}


            <div className="pagination">
              <Pagination
                style={{ cursor: "pointer" }}
                className="mx-auto"
                current={currentPage || 1}
                total={data?.count || 0}
                pageSize={10}
                onChange={handlePaginationChange}
                showSizeChanger={false}
                showQuickJumper={false}
              />

            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default Direct
